import React from 'react';
import { Seo } from '@/components';
import Agradecimento from '@/layouts/RedesFranquias/Agradecimento';

const RedesFranquias = () => {
  return (
    <>
      <Seo
        title="Redes e Franquias Odontológicas - Agradecimento"
        description="Você está prestes a otimizar sua gestão da melhor forma!"
      />
      <Agradecimento />
    </>
  );
};

export default RedesFranquias;
