import { Button } from '@/components';
import React from 'react';
import * as styles from './agradecimento.module.scss';
import dente from '@/assets/icons/dente.svg';
import wink from '@/assets/images/wink-emoji.png';
import simplesDental from '@/assets/images/logo-blue.svg';

import Footer from './Footer';
import { navigate } from 'gatsby';

const Agradecimento = () => {
  return (
    <>
      <div className={styles.container}>
        <img className={styles.headerImg} src={simplesDental} alt="Logo Simples Dental" />
        <img src={wink} alt=";)" />
        <h1 className={styles.title}>
          Demonstração solicitada! Agora é só aguardar o nosso contato ;)
        </h1>
        <p className={styles.desc}>
          Você está prestes a otimizar sua gestão da melhor forma! Recebemos sua solicitação e{' '}
          <span>entraremos em contato em breve.</span>{' '}
        </p>
        <hr className={styles.divider} />
        <p className={styles.desc}>Enquanto isso, fique livre para conhecer o nosso software:</p>
        <Button
          variant="orange"
          onClick={() => navigate('/')}
          icon={dente}
          className={styles.orangeAlternative}
        >
          <span className={styles.text}>Mais sobre Simples Dental</span>
        </Button>
        <Footer isLightTheme={true} />
      </div>
    </>
  );
};

export default Agradecimento;
